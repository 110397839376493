var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"#CCCCCC80"}},[(_vm.type)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"padding":"5px"},attrs:{"xs8":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":_vm.handleReload}},[_vm._v(" refresh ")])],1),_c('v-flex',{staticStyle:{"padding":"5px"},attrs:{"xs2":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"primary","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleChangeParentWidth(-50)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify-minus")])],1)],1),_c('v-flex',{staticStyle:{"padding":"5px"},attrs:{"xs2":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"primary","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleChangeParentWidth(+50)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify-plus")])],1)],1),_c('v-flex',{staticClass:"preview",staticStyle:{"padding":"5px","text-align":"left"},attrs:{"xs12":""}},[(_vm.parentWidth)?_c('preview',{style:({width: (_vm.parentWidth + "px")}),attrs:{"parentWidth":_vm.parentWidth,"url":_vm.prepareURL(_vm.type, _vm.LMUDtouch),"locale":_vm.locale,"size":_vm.prepareSize(_vm.type),"className":"preview","name":_vm.type === 'dtouch' ? 'if-dtouch' : _vm.type === 'totem' ? 'if-totem' : 'if-desktop'}}):_vm._e()],1)],1):_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"dtouchMobilePreview",staticStyle:{"padding":"5px"},attrs:{"xs2":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('dtouch')}}},[_vm._v(" refresh ")]),_c('preview',{attrs:{"url":_vm.prepareDtouch(_vm.LMUDtouch),"locale":_vm.locale,"size":{
            w: 375,
            h: 799,
          },"className":"dtouchMobilePreview","name":"if-dtouch"}})],1),_c('v-flex',{staticClass:"dtouchTotemPreview",staticStyle:{"padding":"5px"},attrs:{"xs3":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('totem')}}},[_vm._v(" refresh ")]),_c('preview',{attrs:{"url":_vm.prepareTotem(_vm.LMUTotem),"locale":_vm.locale,"size":{
            w: 1080,
            h: 1920,
          },"className":"dtouchTotemPreview","name":"if-totem"}})],1),_c('v-flex',{staticClass:"dtouchDesktopPreview",staticStyle:{"padding":"5px"},attrs:{"xs7":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('desktop')}}},[_vm._v(" refresh ")]),_c('preview',{attrs:{"url":_vm.prepareDesktop(_vm.LMUDesktop),"locale":_vm.locale,"size":{
            w: 1920,
            h: 1080,
          },"className":"dtouchDesktopPreview","name":"if-desktop"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }