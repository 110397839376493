<template>
  <div style="background-color: #CCCCCC80;">
      <v-layout
        v-if="type"
        wrap
      >
        <v-flex xs8 style="padding: 5px;">
          <v-btn
            @click="handleReload"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
        </v-flex>
        <v-flex xs2 style="padding: 5px;">
          <v-btn
            @click="handleChangeParentWidth(-50)"
            color="primary"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            <v-icon small>mdi-magnify-minus</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs2 style="padding: 5px;">
          <v-btn
            @click="handleChangeParentWidth(+50)"
            color="primary"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            <v-icon small>mdi-magnify-plus</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 style="padding: 5px;text-align: left;" class="preview">
            <preview
              v-if="parentWidth"
              :style="{width: `${parentWidth}px`}"
              :parentWidth="parentWidth" 
              :url="prepareURL(type, LMUDtouch)"
              :locale="locale"
              :size="prepareSize(type)"
              className="preview"
              :name="type === 'dtouch' ? 'if-dtouch' : type === 'totem' ? 'if-totem' : 'if-desktop'"
            />
        </v-flex>
      </v-layout>
      <v-layout
        v-else
        wrap
      >
        <v-flex xs2 style="padding: 5px;" class="dtouchMobilePreview">
          <v-btn
            @click="handleRefresh('dtouch')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareDtouch(LMUDtouch)"
            :locale="locale"
            :size="{
              w: 375,
              h: 799,
            }"
            className="dtouchMobilePreview"
            name="if-dtouch"
          />
        </v-flex>
        <v-flex xs3 style="padding: 5px;" class="dtouchTotemPreview">
          <v-btn
            @click="handleRefresh('totem')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareTotem(LMUTotem)"
            :locale="locale"
            :size="{
              w: 1080,
              h: 1920,
            }"
            className="dtouchTotemPreview"
            name="if-totem"
          />
        </v-flex>
        <v-flex xs7 style="padding: 5px;" class="dtouchDesktopPreview">
          <v-btn
            @click="handleRefresh('desktop')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareDesktop(LMUDesktop)"
            :locale="locale"
            :size="{
              w: 1920,
              h: 1080,
            }"
            className="dtouchDesktopPreview"
            name="if-desktop"
          />
        </v-flex>
      </v-layout>
  </div>
</template>
<script>
//import api from '@/services/api'
import Preview from './Preview'
export default {
  components: {
    Preview,
  },
  props: {
    locale: {
      type: String,
      default: 'es'
    },
  },
  data: () => ({
    loading: true, 
    parentWidth: 100,
    dialog: false,
    LMUDtouch: null,
    LMUTotem: null,
    LMUDesktop: null,
  }),
  computed: {
    domain () {
      return this.$route.params.id
    },
    type () {
      const aux = this.$route.params.type
      return aux ? aux : null
    },
    port () {
      return '8006'
    },
    h () {
      return process.env.NODE_ENV === 'development' ? `http` : 'https'
    },
    b () {
      return process.env.NODE_ENV === 'development' ? `local:${this.port}` : 'es'
    },
  },
  mounted () {
    setTimeout(() => {
      let a = document.getElementsByClassName('preview')[0].offsetWidth - 100
      this.parentWidth = a
    }, 1000)
  },
  methods: {
    handleChangeParentWidth (v) {
      this.parentWidth += v
      this.handleRefresh(this.type)
    },
    handleReload () {
      window.location.reload()
    },
    handleRefresh (type) {
      for (const i of document.getElementsByName(`if-${type}`)){
        i.src = ''
        setTimeout(() => {
          i.src = type === 'mobile' ? this.prepareDtouch() : type === 'totem' ? this.prepareTotem() : this.prepareDesktop()
        }, 1000)
      }
    },
    prepareSize (type) {
      if (type === 'mobile') return { w: 375, h: 799 }
      else if (type === 'totem') return { w: 1080, h: 1920 }
      else return { w: 1920, h: 1080 }
    },
    prepareURL (type) {
      if (type === 'mobile') return this.prepareDtouch()
      else if (type === 'totem') return this.prepareTotem()
      else return this.prepareDesktop()
    },
    prepareDtouch () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/?fm=true&lmu=${lmu}`
    },
    prepareTotem () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/totem?fullscreen=true&lmu=${lmu}`
    },
    prepareDesktop () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/?lmu=${lmu}`
    },
  },
}
</script>
